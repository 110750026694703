import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  DashboardSignOff,
  OpenTicketRequest,
  RpaDashboard,
  RpaDashboardResponse,
  SignOffData,
  SignOffDataResponse,
  SignOffHistoryItem,
  TicketType,
  EffectiveDatesResponse,
  SignOffInfoResponse,
  SignOffEvidenceFile,
  EditBotRunner
} from '@/types/om27.types';
import {
  dashboardDefaults,
  signOffDefaults,
  signOffHistoryDefaults,
} from '@/utils/data';
import { VerificationPayload, VerificationCommentPayload } from '@/types/signoff.types';

export function getRpaOpsDashboard(): AxiosPromise<RpaDashboard> {
  return axios.get<RpaDashboardResponse>(URLs.RpaOpsDashboard).then(res => {
    return {
      ...res,
      data: dashboardDefaults(res.data),
    };
  });
}

export function getRpaOpsSignOff(signOffId: number): AxiosPromise<SignOffData> {
  return axios
    .get<SignOffDataResponse>(URLs.RpaOpsSignOffData, {
      params: {
        signoffId: signOffId,
      },
    })
    .then(res => {
      return {
        ...res,
        data: signOffDefaults(res.data),
      };
    });
}

export function getRpaOpsSignOffHistory(logicalBotId: number): AxiosPromise<SignOffHistoryItem[]> {
  return axios
    .get<Partial<SignOffHistoryItem>[]>(URLs.RpaOpsSignOffHistory, {
      params: {
        logicalBotId,
      },
    })
    .then(res => Object.assign(res, { data: signOffHistoryDefaults(res.data) }));
}

/**
 * only works with our mocks
 */
export function resetData(): AxiosPromise<number> {
  return axios.post(URLs.RpaOpsDataReset);
}

export function signOff(data: DashboardSignOff): AxiosPromise<unknown> {
  return axios.post(URLs.RpaOpsSignOff, data);
}

export function openTicket(type: TicketType, data: OpenTicketRequest): AxiosPromise<unknown> {
  switch (type) {
    case 'blocking':
      return axios.post(URLs.RpaOpsBlockingTicket, data);
    case 'nonblocking':
      return axios.post(URLs.RpaOpsNonBlockingTicket, data);
    case 'intermittent':
      return axios.post(URLs.RpaOpsIntermittentTicket, data);
  }
}

export function addVerification(data: VerificationPayload): AxiosPromise<unknown> {
  return axios.post(URLs.RpaOpsAddVerification, data);
}

export function getUnassignedBots(): AxiosPromise<unknown> {
  return axios.get(URLs.RpaOpsUnassignedBots);
}

export function getSignOffEffectiveDates(signoffId: number): AxiosPromise<EffectiveDatesResponse> {
  return axios.get(URLs.RpaOpsSignOffEffectiveDates, {
    params: { signoffId },
  });
}

export function signOffDetailsBySignOffId(signoffId: string): AxiosPromise<SignOffInfoResponse> {
  return axios.get(URLs.RpaOpsSignOffDetailsBySignOffId, {
    params: { signoffId },
  });
}

export function addVerificationComment(data: VerificationCommentPayload, signOffUserMudId: string): AxiosPromise<any> {
  return axios.post(URLs.RpaOpsAddVerificationComment, data, {
    headers: {
      'gsk-signer-mud-id': signOffUserMudId,
    },
  });
}

export function signOffCaptureFileNames(
  signOffId: number, data: SignOffEvidenceFile[],
): AxiosPromise<any> {
  return axios.post(URLs.RpaOpsSignOffFilesCaptureNames, {
    signOffId,
    data,
  });
}

export function authenticateSharepoint(): AxiosPromise<string> {
  return axios.post(URLs.RpaOpsSharepointAuthenticate);
}

export function editBotRunner(data: EditBotRunner): AxiosPromise<string> {
  return axios.post(URLs.RpaOpsEditBotRunner, data);
}